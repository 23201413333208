import { assert } from "@qrbite/shared/utils/general.utils";
import useAuth from "./useAuth";

export default function useAccount() {
  const auth = useAuth();

  assert(auth.isAuthenticated, "Must be authenticated");

  return auth.user;
}
