import { z } from "zod";

export const MenuId = z.string().brand("MenuId");
export type MenuId = z.infer<typeof MenuId>;

export const CategoryId = z.string().brand("CategoryId");
export type CategoryId = z.infer<typeof CategoryId>;

export const ItemId = z.string().brand("ItemId");
export type ItemId = z.infer<typeof ItemId>;

export const VariantId = z.string().brand("VariantId");
export type VariantId = z.infer<typeof VariantId>;

export const VariantOptionId = z.string().brand("VariantOptionId");
export type VariantOptionId = z.infer<typeof VariantOptionId>;

export const zCurrency = z.string().brand("Currency");
export type Currency = z.infer<typeof zCurrency>;

export const BusinessHourId = z.string().brand("BusinessHourId");
export type BusinessHourId = z.infer<typeof BusinessHourId>;

export const CustomerId = z.string().brand("CustomerId");
export type CustomerId = z.infer<typeof CustomerId>;

export const UserId = z.string().brand("UserId");
export type UserId = z.infer<typeof UserId>;

export const OrderId = z.string().brand("OrderId");
export type OrderId = z.infer<typeof OrderId>;

export const SubscriptionId = z.string().brand("SubscriptionId");
export type SubscriptionId = z.infer<typeof SubscriptionId>;
