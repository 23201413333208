import type { InitOptions } from "i18next";
import enTranslation from "./locales/en/translation.json";
import heTranslation from "./locales/he/translation.json";
import trTranslation from "./locales/tr/translation.json";

const i18nextConfig = {
  fallbackLng: "en",
  debug: true,
  resources: {
    en: {
      translation: enTranslation,
    },
    he: {
      translation: heTranslation,
    },
    tr: {
      translation: trTranslation,
    },
  },
} as const satisfies InitOptions;

export default i18nextConfig;
