import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";

import { theme } from "@/theme";
import { MantineProvider, MantineThemeOverride } from "@mantine/core";
import { Notifications } from "@mantine/notifications";

export function MantineWrapper(props: {
  children: React.ReactNode;
  themeOverride?: MantineThemeOverride;
}) {
  return (
    <MantineProvider theme={{ ...theme, ...props.themeOverride }}>
      <Notifications />
      {props.children}
    </MantineProvider>
  );
}
