import i18n from "i18next";

export function getLanguageCodeFromLocale() {
  const languageCode = navigator.language.split("-")[0];

  if (languageCode?.length === 2) {
    return languageCode;
  }

  return null;
}

export function getCurrentLocale() {
  return i18n.language;
}
