import { app } from "@/config";
import { Database } from "@/core/database.types";
import { createClient } from "@supabase/supabase-js";

const supabase = createClient<Database>(
  app.apiUrl,
  import.meta.env.VITE_SUPABASE_ANON_KEY,
);

export default supabase;
