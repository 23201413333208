export const currencySymbols = {
  AZN: "₼",
  BAM: "КМ",
  BBD: "$",
  BDT: "৳",
  BGN: "лв.",
  BHD: ".د.ب",
  BIF: "FBu",
  BMD: "$",
  BND: "$",
  BOB: "Bs",
  BOV: "-",
  BRL: "R$",
  BSD: "$",
  BTN: "Nu.",
  BWP: "P",
  BYN: "Br",
  BZD: "$",
  CAD: "$",
  EUR: "€",
  FJD: "$",
  FKP: "£",
  GBP: "£",
  GEL: "₾",
  GHS: "₵",
  GIP: "£",
  GMD: "D",
  GNF: "FG",
  GTQ: "Q",
  GYD: "$",
  HKD: "$",
  HNL: "L",
  HTG: "G",
  HUF: "Ft",
  IDR: "Rp",
  ILS: "₪",
  INR: "₹",
  IQD: "د.ع",
  IRR: "﷼",
  ISK: "kr",
  JEP: "£",
  JMD: "$",
  JOD: "د.أ",
  JPY: "¥",
  KES: "KSh",
  KGS: "сом",
  KHR: "៛",
  KMF: "CF",
  KPW: "₩",
  KRW: "₩",
  KWD: "د.ك",
  KYD: "$",
  KZT: "₸",
  LAK: "₭",
  LBP: "ل.ل.",
  LKR: "₨",
  LRD: "$",
  LSL: "L",
  LYD: ".د",
  MAD: "د.م.",
  MDL: "L",
  MGA: "Ar",
  MKD: "ден",
  MMK: "K",
  MNT: "₮",
  MOP: "MOP$",
  MRU: "UM",
  MUR: "₨",
  MVR: "Rf.",
  MWK: "K",
  MXN: "$",
  MXV: "-",
  MYR: "RM",
  MZN: "MT",
  NAD: "$",
  NGN: "₦",
  NIO: "C$",
  NOK: "kr",
  NPR: "रु",
  NZD: "$",
  OMR: "ر.ع.",
  PAB: "B/.",
  PEN: "S/",
  PKG: "K",
  PHP: "₱",
  PKR: "₨",
  PLN: "zł",
  PYG: "₲",
  QAR: "ر.ق",
  RON: "L",
  RSD: "дин",
  RUB: "₽",
  RWF: "FRw",
  SAR: "ر.س",
  SBD: "$",
  SCR: "₨",
  SDG: "£",
  SEK: "kr",
  SGD: "$",
  SHP: "£",
  SLE: "Le",
  SLL: "Le",
  SOS: "Sh.So.",
  SRD: "$",
  STN: "Db",
  SSP: "£",
  SYP: "£",
  SZL: "L",
  THB: "฿",
  TJS: "SM",
  TMT: "m",
  TND: "د.ت",
  TOP: "T$",
  TRY: "₺",
  TTD: "$",
  TWD: "$",
  TZS: "TSh",
  UAH: "₴",
  AED: "د.إ",
  AFN: "؋",
  ALL: "L",
  AMD: "֏",
  ANG: "ƒ",
  AOA: "Kz",
  ARS: "$",
  AUD: "$",
  AWG: "ƒ",
  CDF: "FC",
  CHF: "Fr",
  CLF: "UF",
  CLP: "$",
  CNY: "¥",
  COP: "$",
  CRC: "₡",
  CUC: "$",
  CUP: "$",
  CVE: "Esc",
  CZK: "Kč",
  DJF: "Fdj",
  DKK: "kr",
  DOP: "$",
  DZD: "دج",
  EGP: "£",
  ETB: "ብር",
  ERN: "Nkf",
  UGX: "USh",
  USD: "$",
  UYU: "$",
  UZS: "сум",
  VED: "Bs.",
  VES: "Bs.S",
  VND: "₫",
  VUV: "VT",
  WST: "$",
  XAF: "FCFA",
  XCD: "$",
  XDR: "SDR",
  XOF: "CFA",
  XPF: "₣",
  YER: "ر.ي",
  ZAR: "R",
  ZMW: "K",
  ZWL: "$",
};
