import { Group } from "@mantine/core";
import { Link } from "react-router-dom";

import Logo from "@/components/Logo";
import { StickyHeader } from "@/components/sticky-header";
import { CurrentUser } from "./current-user";
import classes from "./header.module.css";
import { SidebarButton } from "./sidebar-button";

export function Header() {
  return (
    <StickyHeader className={classes.root}>
      <div className={classes.rightContent}>
        <SidebarButton />
        <Link className={classes.logo} to="/">
          <Logo size={20} />
        </Link>
      </div>

      <Group>
        <CurrentUser />
      </Group>
    </StickyHeader>
  );
}
