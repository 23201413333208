import { Tooltip } from "@mantine/core";

export default {
  Tooltip: Tooltip.extend({
    defaultProps: {
      radius: "md",
      withArrow: true,
    },
  }),
};
