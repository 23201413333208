import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";

import SetTranslation from "@/components/SetTranslation";
import { AuthGuard } from "@/guards/auth-guard";
import { GuestGuard } from "@/guards/guest-guard";
import { AuthLayout } from "@/layouts/auth";
import { DashboardLayout } from "@/layouts/dashboard";
import i18nextConfig from "@qrbite/localization/i18next.config";
import { LazyPage } from "./lazy-page";
import { MantineRouteWrapper } from "./mantine-route-wrapper";
import { routes } from "./paths";

const router = createBrowserRouter([
  {
    path: "/",
    element: LazyPage(() => import("@/pages/home")),
  },
  {
    path: routes.menu.shortName(":shortName"),
    element: LazyPage(() => import("@/pages/Menu/Menu.Page")),
  },
  {
    path: routes.menu.qr(":id"),
    element: LazyPage(() => import("@/pages/Menu/Menu.Page")),
  },
  {
    path: routes.legal.privacy,
    element: LazyPage(() => import("@/pages/home/privacy")),
  },
  {
    path: routes.legal.terms,
    element: LazyPage(() => import("@/pages/home/terms")),
  },
  {
    path: routes.blog.root,
    element: LazyPage(() => import("@/pages/blog/index")),
  },
  {
    path: routes.blog.post(":slug"),
    element: LazyPage(() => import("@/pages/blog/post")),
  },
  {
    element: <MantineRouteWrapper />,
    children: [
      {
        path: routes.auth.root,
        element: (
          <GuestGuard>
            <AuthLayout />
          </GuestGuard>
        ),
        children: [
          {
            index: true,
            path: routes.auth.root,
            element: <Navigate replace to={routes.auth.login} />,
          },
          {
            path: routes.auth.login,
            element: LazyPage(() => import("@/pages/auth/login")),
          },
          {
            path: routes.auth.register,
            element: LazyPage(() => import("@/pages/auth/register")),
          },
          {
            path: routes.auth.forgotPassword,
            element: LazyPage(() => import("@/pages/auth/forgot-password")),
          },
          {
            path: routes.auth.verificationEmailSent,
            element: LazyPage(() => import("@/pages/auth/verification-email-sent")),
          },
        ],
      },
      {
        element: (
          <AuthGuard>
            <AuthLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: routes.account.resetPassword,
            element: LazyPage(() => import("@/pages/auth/reset-password")),
          },
        ],
      },
      {
        path: routes.dashboard.root,
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            index: true,
            path: routes.dashboard.root,
            element: LazyPage(() => import("@/pages/dashboard")),
          },
          {
            path: routes.dashboard.editMenu(":id"),
            element: LazyPage(() => import("@/pages/dashboard/edit-menu")),
          },
          {
            path: routes.dashboard.addMenu(),
            element: LazyPage(() => import("@/pages/dashboard/add-menu")),
          },
        ],
      },
      {
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: routes.account.billing,
            element: LazyPage(() => import("@/pages/dashboard/billing")),
          },
          {
            path: routes.account.settings,
            element: LazyPage(() => import("@/pages/dashboard/account")),
          },
          {
            path: routes.account.support,
            element: LazyPage(() => import("@/pages/dashboard/support")),
          },
        ],
      },
      {
        children: [
          {
            path: routes.return.subscriptionReturn,
            element: LazyPage(() => import("@/pages/return/subscription-return")),
          },
        ],
      },
    ],
  },
  ...Object.keys(i18nextConfig.resources).map((language) => ({
    path: `/${language}`,
    element: <SetTranslation language={language} />,
  })),
]);

export function Router() {
  return <RouterProvider router={router} />;
}
