import { ComponentType, ElementType, ReactNode, Suspense } from "react";
import { lazy } from "./react-lazy-with-reload.ts";

// Type guard to check if a component can have a displayName
function isReactComponentType(
  component: ElementType,
): component is ComponentType {
  // Check if the component is a function or class component
  return (
    typeof component === "function" &&
    // For class components, check if it's a React component
    "prototype" in component &&
    (component.prototype?.isReactComponent || component.prototype?.render)
  );
}

const Loadable = (Component: ElementType) => {
  const WrappedComponent = (props: object): ReactNode => (
    <Suspense>
      <Component {...props} />
    </Suspense>
  );

  if (isReactComponentType(Component)) {
    const displayName = Component.displayName || Component.name || "Component";
    WrappedComponent.displayName = `Loadable(${displayName})`;
  }

  return WrappedComponent;
};

export function LazyPage(
  callback: () => Promise<{ default: ComponentType<unknown> }>,
) {
  const Component = Loadable(lazy(callback));
  return <Component />;
}
