import { queryClient } from "@/core/query-client";
import AuthProvider from "@/providers/auth-provider";
import { Router } from "@/routes/router";
import { DirectionProvider } from "@radix-ui/react-direction";
import { QueryClientProvider } from "@tanstack/react-query";
import { HelmetProvider } from "react-helmet-async";
import useDocumentDirection from "./hooks/useDocumentDirection";

export default function App() {
  const dir = useDocumentDirection();

  return (
    <DirectionProvider dir={dir}>
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <Router />
          </AuthProvider>
        </QueryClientProvider>
      </HelmetProvider>
    </DirectionProvider>
  );
}
