import React from "react";
import { useTranslation } from "react-i18next";

export default function useDocumentDirection() {
  const { i18n } = useTranslation();

  const dir = isRTL(i18n.language) ? "rtl" : "ltr";

  React.useEffect(() => {
    const htmlElement = document.documentElement;

    htmlElement.setAttribute("dir", dir);
  }, [dir]);

  return dir;
}

function isRTL(language: string) {
  return ["ar", "he", "fa", "ur"].includes(sanitizeLanguage(language));
}

function sanitizeLanguage(language: string) {
  return language.split("-")[0] ?? "";
}
