import React from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";

export default function SetTranslation({ language }: { language: string }) {
  const { i18n } = useTranslation();

  React.useEffect(() => {
    localStorage.setItem("i18nextLng", language);
    i18n.changeLanguage(language);
  }, [language, i18n]);

  return <Navigate replace to="/" />;
}
