import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";

import { MantineWrapper } from "@/components/MantineWrapper";
import { Outlet } from "react-router-dom";

export function MantineRouteWrapper() {
  return (
    <MantineWrapper>
      <Outlet />
    </MantineWrapper>
  );
}
