import "@af-utils/scrollend-polyfill";
import i18nextConfig from "@qrbite/localization/i18next.config";
import * as Sentry from "@sentry/react";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import React from "react";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import App from "./App.tsx";
import { sentryConfig } from "./core/sentry.config.ts";
import { enableMocking } from "./enable-mocking.ts";
import "./index.css";

if (import.meta.env.VITE_ENV === "production") {
  Sentry.init(sentryConfig);
}

i18n.use(LanguageDetector).init({
  ...i18nextConfig,
  detection: {
    caches: [],
    lookupLocalStorage: "i18nextLng",
    order: ["localStorage", "navigator"],
  },
});

enableMocking().then(() => {
  ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </React.StrictMode>,
  );
});
