import { Box, BoxProps, ElementProps } from "@mantine/core";

interface LogoProps
  extends Omit<BoxProps, "children" | "ref">,
    ElementProps<"svg", keyof BoxProps> {
  size?: string | number;
}

export default function Logo({ size, style, ...props }: LogoProps) {
  return (
    <Box
      component="svg"
      fill="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      style={{ height: size, ...style }}
      viewBox="0 0 138 36"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.11735 14.4208L11.3053 12.3158L13.4293 16.466L9.24143 18.571L7.11735 14.4208Z"
        fill="currentColor"
      />
      <path
        d="M12.4275 24.7964L16.6155 22.6914L18.7395 26.8417L14.5516 28.9466L12.4275 24.7964Z"
        fill="currentColor"
      />
      <path
        d="M23.8991 11.2036L19.7112 13.3086L17.5871 9.15833L21.775 7.05335L23.8991 11.2036Z"
        fill="currentColor"
      />
      <path
        clipRule="evenodd"
        d="M28.9804 32.4585C28.1724 33.0507 27.3038 33.5842 26.3775 34.0498C17.433 38.5456 6.50448 35.0044 1.9679 26.1403C-2.56868 17.2763 1.00464 6.44599 9.94913 1.95021C18.8936 -2.54558 29.8222 0.995605 34.3588 9.85966C35.1442 11.3944 35.6865 12.988 36 14.5975L35.7627 14.661C35.0744 15.2142 34.7986 15.6812 34.5015 16.7813C32.0414 17.722 31.4403 18.6265 31.4609 20.7688C31.4526 20.7745 31.4442 20.7803 31.436 20.786L31.3033 20.5267L29.2093 21.5792L28.1473 19.5041L30.2412 18.4516L29.1792 16.3765L27.0852 17.429L26.0232 15.3539L23.9292 16.4063L24.9913 18.4815L22.8973 19.534L21.8353 17.4588L19.7413 18.5113L20.8034 20.5864L22.8973 19.534L23.9594 21.6091L21.8654 22.6616L22.9274 24.7367L25.0214 23.6842L26.0834 25.7593L28.1774 24.7068L27.1153 22.6317L29.2093 21.5792L29.7219 22.5808C29.434 23.2395 29.4172 24.0203 29.6168 25.1198C27.9691 26.8813 27.7702 28.0361 29.31 30.5303C29.062 31.2844 28.9409 31.9036 28.9804 32.4585ZM8.20951 21.6986L16.5853 17.4887L12.3372 9.18818L3.96136 13.3981L8.20951 21.6986ZM13.5197 32.0743L21.8955 27.8643L17.6474 19.5638L9.27155 23.7738L13.5197 32.0743ZM14.4311 8.13569L18.6793 16.4362L27.0551 12.2262L22.8069 3.92574L14.4311 8.13569Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M26.0533 20.5566L23.9594 21.6091L25.0214 23.6842L27.1153 22.6317L26.0533 20.5566Z"
        fill="currentColor"
      />
      <path
        d="M26.0533 20.5566L24.9913 18.4815L27.0852 17.429L28.1473 19.5041L26.0533 20.5566Z"
        fill="currentColor"
      />
      <path
        d="M62.1699 25.457C63.1634 26.168 64.2435 26.7331 65.4102 27.1523L63.9199 30.0098C63.3092 29.8275 62.7122 29.5768 62.1289 29.2578C62.0013 29.194 61.1035 28.6016 59.4355 27.4805C58.123 28.0547 56.6693 28.3418 55.0742 28.3418C51.9935 28.3418 49.5781 27.4349 47.8281 25.6211C46.0872 23.8073 45.2168 21.2598 45.2168 17.9785C45.2168 14.7064 46.0918 12.1634 47.8418 10.3496C49.5918 8.52669 51.9661 7.61523 54.9648 7.61523C57.9362 7.61523 60.2923 8.52669 62.0332 10.3496C63.7741 12.1634 64.6445 14.7064 64.6445 17.9785C64.6445 19.7103 64.403 21.2324 63.9199 22.5449C63.5553 23.5475 62.972 24.5182 62.1699 25.457ZM58.916 23.1738C59.4355 22.5632 59.8229 21.8249 60.0781 20.959C60.3424 20.0931 60.4746 19.0996 60.4746 17.9785C60.4746 15.6634 59.9642 13.9362 58.9434 12.7969C57.9225 11.6484 56.5872 11.0742 54.9375 11.0742C53.2878 11.0742 51.9479 11.6484 50.918 12.7969C49.8971 13.9453 49.3867 15.6725 49.3867 17.9785C49.3867 20.321 49.8971 22.0755 50.918 23.2422C51.9479 24.3997 53.2467 24.9785 54.8145 24.9785C55.3978 24.9785 55.9492 24.8828 56.4688 24.6914C55.6484 24.1536 54.8145 23.7344 53.9668 23.4336L55.1016 21.123C56.4323 21.5788 57.7038 22.2624 58.916 23.1738Z"
        fill="currentColor"
      />
      <path
        d="M67.8438 28V7.95703H76.3613C78.5033 7.95703 80.0573 8.13932 81.0234 8.50391C81.9987 8.85938 82.778 9.4974 83.3613 10.418C83.9447 11.3385 84.2363 12.3913 84.2363 13.5762C84.2363 15.0801 83.7943 16.3242 82.9102 17.3086C82.026 18.2839 80.7044 18.8991 78.9453 19.1543C79.8203 19.6647 80.5404 20.2253 81.1055 20.8359C81.6797 21.4466 82.4499 22.5312 83.416 24.0898L85.8633 28H81.0234L78.0977 23.6387C77.0586 22.0801 76.3477 21.1003 75.9648 20.6992C75.582 20.2891 75.1764 20.0111 74.748 19.8652C74.3197 19.7103 73.6406 19.6328 72.7109 19.6328H71.8906V28H67.8438ZM71.8906 16.4336H74.8848C76.8262 16.4336 78.0384 16.3516 78.5215 16.1875C79.0046 16.0234 79.3828 15.7409 79.6562 15.3398C79.9297 14.9388 80.0664 14.4375 80.0664 13.8359C80.0664 13.1615 79.8841 12.6191 79.5195 12.209C79.1641 11.7897 78.6582 11.5254 78.002 11.416C77.6738 11.3704 76.6895 11.3477 75.0488 11.3477H71.8906V16.4336Z"
        fill="currentColor"
      />
      <path
        d="M88.0781 7.95703H96.0898C97.6758 7.95703 98.8561 8.02539 99.6309 8.16211C100.415 8.28971 101.112 8.56315 101.723 8.98242C102.342 9.40169 102.857 9.96224 103.268 10.6641C103.678 11.3568 103.883 12.1361 103.883 13.002C103.883 13.9408 103.628 14.8021 103.117 15.5859C102.616 16.3698 101.932 16.9577 101.066 17.3496C102.288 17.7051 103.227 18.3112 103.883 19.168C104.539 20.0247 104.867 21.0319 104.867 22.1895C104.867 23.1009 104.653 23.9896 104.225 24.8555C103.805 25.7122 103.227 26.4004 102.488 26.9199C101.759 27.4303 100.857 27.7448 99.7812 27.8633C99.1068 27.9362 97.4798 27.9818 94.9004 28H88.0781V7.95703ZM92.125 11.293V15.9277H94.7773C96.3542 15.9277 97.334 15.9049 97.7168 15.8594C98.4095 15.7773 98.9518 15.5404 99.3438 15.1484C99.7448 14.7474 99.9453 14.2233 99.9453 13.5762C99.9453 12.9564 99.7721 12.4551 99.4258 12.0723C99.0885 11.6803 98.5827 11.4434 97.9082 11.3613C97.5072 11.3158 96.3542 11.293 94.4492 11.293H92.125ZM92.125 19.2637V24.623H95.8711C97.3294 24.623 98.2546 24.582 98.6465 24.5C99.248 24.3906 99.7357 24.1263 100.109 23.707C100.492 23.2786 100.684 22.709 100.684 21.998C100.684 21.3965 100.538 20.8861 100.246 20.4668C99.9544 20.0475 99.5306 19.7422 98.9746 19.5508C98.4277 19.3594 97.2337 19.2637 95.3926 19.2637H92.125Z"
        fill="currentColor"
      />
      <path
        d="M108.271 11.5117V7.95703H112.113V11.5117H108.271ZM108.271 28V13.4805H112.113V28H108.271Z"
        fill="currentColor"
      />
      <path
        d="M122.723 13.4805V16.543H120.098V22.3945C120.098 23.5794 120.12 24.2721 120.166 24.4727C120.221 24.6641 120.335 24.8236 120.508 24.9512C120.69 25.0788 120.909 25.1426 121.164 25.1426C121.52 25.1426 122.035 25.0195 122.709 24.7734L123.037 27.7539C122.144 28.1367 121.132 28.3281 120.002 28.3281C119.309 28.3281 118.685 28.2142 118.129 27.9863C117.573 27.7493 117.163 27.4486 116.898 27.084C116.643 26.7103 116.465 26.209 116.365 25.5801C116.283 25.1335 116.242 24.2311 116.242 22.873V16.543H114.479V13.4805H116.242V10.5957L120.098 8.35352V13.4805H122.723Z"
        fill="currentColor"
      />
      <path
        d="M133.797 23.3789L137.625 24.0215C137.133 25.4251 136.354 26.4961 135.287 27.2344C134.23 27.9635 132.904 28.3281 131.309 28.3281C128.784 28.3281 126.915 27.5033 125.703 25.8535C124.746 24.5319 124.268 22.8639 124.268 20.8496C124.268 18.4434 124.896 16.5612 126.154 15.2031C127.412 13.8359 129.003 13.1523 130.926 13.1523C133.086 13.1523 134.79 13.8678 136.039 15.2988C137.288 16.7207 137.885 18.9036 137.83 21.8477H128.205C128.232 22.987 128.542 23.8757 129.135 24.5137C129.727 25.1426 130.465 25.457 131.35 25.457C131.951 25.457 132.457 25.293 132.867 24.9648C133.277 24.6367 133.587 24.1081 133.797 23.3789ZM134.016 19.4961C133.988 18.3841 133.701 17.541 133.154 16.9668C132.607 16.3835 131.942 16.0918 131.158 16.0918C130.32 16.0918 129.627 16.3971 129.08 17.0078C128.533 17.6185 128.264 18.4479 128.273 19.4961H134.016Z"
        fill="currentColor"
      />
    </Box>
  );
}
