import { ReactNode, createContext, useEffect, useMemo, useState } from "react";

import { routes } from "@/routes";
import { useGetAccount } from "@/services/resources/account";
import supabase from "@/services/supabase";
import { UserId } from "@qrbite/shared/schema";
import { Session } from "@supabase/supabase-js";

export type AuthContextValues =
  | {
      isAuthenticated: false;
      isInitialized: boolean;
    }
  | {
      isAuthenticated: true;
      isInitialized: true;
      user: {
        displayName: string;
        id: UserId;
        email: string;
        avatarUrl: string | null;
        firstName?: string | undefined;
        lastName?: string | undefined;
      };
    };

export const AuthContext = createContext<AuthContextValues | null>(null);

interface AuthProviderProps {
  children: ReactNode;
}

export default function AuthProvider({ children }: AuthProviderProps) {
  const [, setSession] = useState<Session | null>(null);
  const accountQuery = useGetAccount();

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      setSession(session);

      if (event == "PASSWORD_RECOVERY") {
        document.location.href = routes.account.resetPassword;
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  const value = useMemo(() => {
    switch (accountQuery.status) {
      case "error":
        return {
          isAuthenticated: false,
          isInitialized: true,
        } as const;

      case "pending":
      case "success": {
        if (accountQuery.data === undefined) {
          return {
            isAuthenticated: false,
            isInitialized: false,
          } as const;
        }

        const user = accountQuery.data;

        return user === null
          ? ({
              isAuthenticated: false,
              isInitialized: true,
            } as const)
          : ({
              isAuthenticated: true,
              isInitialized: true,
              user: user,
            } as const);
      }
    }
  }, [accountQuery.data, accountQuery.status]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
