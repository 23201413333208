import { assert } from "@qrbite/shared/utils/general.utils";
import { emptyToNull, extractBucketAndObjectKey } from "@/utils/string.utils";
import { Bucket } from "@qrbite/db/constants/buckets";
import { handleCompressImages } from "./image-compressor";
import { User } from "./resources/user";
import supabase from "./supabase";

type ImageToCompress = {
  type: "avatar";
  blobUrl: string;
};

type ImageToUpload = {
  type: "avatar";
  file: File;
};

type UpdateProgress =
  | {
      type: "compressingImage" | "uploadingImage";
      current: number;
      total: number;
    }
  | {
      type: "deletingImages";
      total: number;
    }
  | {
      type: "updatingAccount";
    };

export async function updateAccount(
  currentUser: User,
  user: User,
  onProgress: (data: UpdateProgress) => void,
): Promise<void> {
  const { imageToUpload, imageToDelete } = getImagesToProcess(
    user,
    currentUser,
  );

  const compressedImageToUpload = await (async () => {
    if (!imageToUpload) return;

    const [compressedImage] = await handleCompressImages(
      [imageToUpload.blobUrl],
      onProgress,
    );

    assert(compressedImage, "Compressed image is undefined");

    return {
      type: "avatar" as const,
      file: compressedImage,
    };
  })();

  await handleUpdateUserData(user, currentUser, onProgress);

  const imageUrlToUpdate = await handleUploadImage(
    user,
    compressedImageToUpload,
    onProgress,
  );

  const finalImageUrl = imageUrlToUpdate ?? user.avatarUrl;

  await handleUpdateImageUrl(user, finalImageUrl);

  await handleDeleteImage(imageToDelete, onProgress);
}

function getImagesToProcess(user: User, currentUser: User) {
  let imageToUpload: ImageToCompress | undefined;
  let imageToDelete: string | undefined;

  if (user.avatarUrl !== currentUser.avatarUrl) {
    if (currentUser.avatarUrl !== null) {
      imageToDelete = currentUser.avatarUrl;
    }

    if (user.avatarUrl !== null) {
      imageToUpload = { type: "avatar", blobUrl: user.avatarUrl };
    }
  }

  return { imageToUpload, imageToDelete };
}

async function handleDeleteImage(
  imageToDelete: string | undefined,
  onProgress: (data: UpdateProgress) => void,
) {
  if (!imageToDelete) return;

  onProgress({ type: "deletingImages", total: 1 });

  const key = extractBucketAndObjectKey(imageToDelete).key;

  const { error } = await supabase.storage.from("avatars").remove([key]);

  if (error) throw error;
}

async function handleUploadImage(
  user: User,
  imageToUpload: ImageToUpload | undefined,
  onProgress: (data: UpdateProgress) => void,
) {
  if (!imageToUpload) return;

  onProgress({
    type: "uploadingImage",
    current: 1,
    total: 1,
  });

  const uploadedUrl = await uploadImage(
    "avatars",
    imageToUpload.file,
    `${user.id}/${imageToUpload.file.name}`,
  );

  return uploadedUrl;
}

async function handleUpdateUserData(
  user: User,
  currentUser: User,
  onProgress: (data: UpdateProgress) => void,
) {
  onProgress({ type: "updatingAccount" });

  const nowString = new Date().toISOString();

  if (
    user.firstName !== currentUser.firstName ||
    user.lastName !== currentUser.lastName
  ) {
    const { error } = await supabase
      .from("User")
      .update({
        firstName: emptyToNull(user.firstName),
        lastName: emptyToNull(user.lastName),
        updatedAt: nowString,
      })
      .eq("id", user.id);

    if (error) throw error;
  }
}

async function handleUpdateImageUrl(
  user: User,
  imageUrlToUpdate: string | null,
) {
  const { error: imageUrlError } = await supabase
    .from("User")
    .update({ avatarUrl: imageUrlToUpdate })
    .eq("id", user.id);

  if (imageUrlError) throw imageUrlError;
}

async function uploadImage(
  bucket: Bucket,
  file: File,
  path: string,
): Promise<string> {
  const { data, error } = await supabase.storage
    .from(bucket)
    .upload(path, file, { upsert: true });

  if (error) throw error;

  return data.fullPath;
}
