import useAccount from "@/hooks/useAccount";
import LogRocket from "logrocket";
import React from "react";

export default function LogRocketAnalyics() {
  const user = useAccount();

  React.useEffect(() => {
    if (import.meta.env.VITE_ENV !== "production") return;

    LogRocket.init("lv6mo0/qrbite");

    LogRocket.identify(user.id, {
      name: user.displayName,
      email: user.email,
    });
  }, [user]);

  return <></>;
}
