import { UserId } from "@qrbite/shared/schema";
import { z } from "zod";
import supabase from "../supabase";

export const User = z
  .object({
    id: UserId,
    email: z.string().email(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    avatarUrl: z.string().url().nullable(),
  })
  .transform((data) => ({
    ...data,
    displayName: getDisplayName(data),
  }));

export function getDisplayName(params: {
  firstName?: string;
  lastName?: string;
  email: string;
}) {
  return params.firstName !== undefined && params.lastName !== undefined
    ? `${params.firstName} ${params.lastName}`
    : (params.firstName ?? params.lastName ?? params.email);
}

export type User = z.infer<typeof User>;

export async function getAccessToken() {
  const { data: session, error } = await supabase.auth.getSession();

  if (error) {
    throw error;
  }

  const accessToken = session.session?.access_token;

  if (!accessToken) {
    throw new Error("Access token not found");
  }

  return accessToken;
}
