import { wrapStorageUrl } from "@/utils/string.utils";
import {
  AvatarProps,
  ElementProps,
  Avatar as MantineAvatar,
} from "@mantine/core";
import React from "react";

type Props = Omit<AvatarProps, "src" | "alt"> &
  ElementProps<"div", keyof AvatarProps> & {
    user: {
      avatarUrl: string | null;
      firstName?: string;
      lastName?: string;
    };
  };

const Avatar = React.forwardRef<HTMLDivElement, Props>(
  ({ user, ...props }, ref) => {
    return (
      <MantineAvatar
        ref={ref}
        alt={`${user.firstName} ${user.lastName}`}
        src={getSrc(user.avatarUrl)}
        {...props}
      >
        {formatAvatarInitials({
          firstName: user.firstName,
          lastName: user.lastName,
        })}
      </MantineAvatar>
    );
  },
);
Avatar.displayName = "Avatar";

function getSrc(avatarUrl: string | null) {
  if (avatarUrl === null) return null;

  if (avatarUrl.startsWith("http")) return avatarUrl;

  return wrapStorageUrl(avatarUrl);
}

function formatAvatarInitials(user: {
  firstName: string | undefined;
  lastName: string | undefined;
}) {
  if (
    user.firstName !== undefined &&
    user.lastName !== undefined &&
    user.firstName !== "" &&
    user.lastName !== ""
  ) {
    return `${user.firstName.at(0)?.toUpperCase()}${user.lastName.at(0)?.toUpperCase()}`;
  }

  if (user.firstName !== undefined && user.firstName !== "") {
    return user.firstName.at(0)?.toUpperCase();
  }

  if (user.lastName !== undefined && user.lastName !== "") {
    return user.lastName.at(0)?.toUpperCase();
  }

  return "CU";
}

export default Avatar;
