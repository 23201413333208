import { currencySymbols } from "@qrbite/shared/currency-symbols";
import { Currency } from "@qrbite/shared/schema";
import { assert } from "@qrbite/shared/utils/general.utils";

export function getCurrencySymbol(currency: Currency) {
  const currencyCode = currency.toUpperCase();

  if (!(currencyCode in currencySymbols)) {
    return "";
  }

  return currencySymbols[currencyCode as keyof typeof currencySymbols];
}

export function extractBucketAndObjectKey(url: string): {
  bucket: string;
  key: string;
} {
  // Remove leading slash if present
  const cleanedKey = url.startsWith("/") ? url.slice(1) : url;

  // Find the first slash to separate bucket name and object key
  const firstSlashIndex = cleanedKey.indexOf("/");

  // If there's no slash, the format is invalid
  assert(firstSlashIndex !== -1, "Invalid storage URL format");

  const bucket = cleanedKey.substring(0, firstSlashIndex);
  const key = cleanedKey.substring(firstSlashIndex + 1);

  return { bucket, key };
}

export function wrapStorageUrl(url: string) {
  return url.startsWith("blob:") || url.startsWith("/")
    ? url
    : `https://${import.meta.env.VITE_SUPABSE_PROJECT_ID}.supabase.co/storage/v1/object/public/${url}`;
}

export function emptyToNull(value: string | undefined | null) {
  if (value === undefined || value === null) {
    return value;
  }

  return value === "" ? null : value;
}

const socialBaseUrls = {
  instagram: "https://www.instagram.com/",
  facebook: "https://www.facebook.com/",
  twitter: ["https://twitter.com/", "https://x.com/"],
  tiktok: "https://www.tiktok.com/@",
};

export function extractUsernameFromSocialProfileLink(
  platform: keyof typeof socialBaseUrls,
  link: string
): string {
  const baseUrls = Array.isArray(socialBaseUrls[platform])
    ? socialBaseUrls[platform]
    : [socialBaseUrls[platform]];

  for (const baseUrl of baseUrls) {
    if (link.startsWith(baseUrl)) {
      const username = link.substring(baseUrl.length).split(/[/?]/)[0];
      return username ?? link;
    }
  }

  return link;
}

export function createSocialProfileLink(
  platform: keyof typeof socialBaseUrls,
  input: string
): string {
  const baseUrls = Array.isArray(socialBaseUrls[platform])
    ? socialBaseUrls[platform]
    : [socialBaseUrls[platform]];

  // Check if the input is already a valid URL for any of the platform's URLs
  if (baseUrls.some((baseUrl) => input.startsWith(baseUrl))) {
    return input; // Already a valid link
  }

  // Use the first URL from the list to create a valid profile URL
  return `${baseUrls[0]}${input}`;
}

export function getFileTypeFromUrl(url: string) {
  const extension = url.split(".").pop();
  return extension?.toLowerCase() ?? "";
}

export function getPreviewImage(imageUrl: string, width?: number) {
  return `https://cdn.qrbite.co/cdn-cgi/image/width=${width ?? 100}/${imageUrl}`;
}
