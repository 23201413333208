import { Drawer } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { HamburgerButton } from "@/components/hamburger-button";
import Logo from "@/components/Logo";
import { Sidebar } from "../sidebar";

export function SidebarButton() {
  const location = useLocation();
  const [opened, { open, close }] = useDisclosure(false);

  useEffect(() => {
    // Close sidebar on navigate
    close();
  }, [close, location.pathname]);

  return (
    <>
      <Drawer.Root opened={opened} size="270px" onClose={close}>
        <Drawer.Overlay />
        <Drawer.Content>
          <Drawer.Header mb="md" px="1.725rem">
            <Logo w="5rem" />
          </Drawer.Header>
          <Drawer.Body>
            <Sidebar />
          </Drawer.Body>
        </Drawer.Content>
      </Drawer.Root>
      <HamburgerButton display={{ xl: "none" }} onClick={open} />
    </>
  );
}
