export function fmap<T, U>(
  value: T | null | undefined,
  cb: (value: T) => U,
): U | undefined {
  if (value !== undefined && value !== null) {
    return cb(value);
  }

  return undefined;
}

export function assert(
  condition: unknown,
  message: string | (() => string),
): asserts condition {
  if (!condition) {
    throw new Error(typeof message === "function" ? message() : message);
  }
}

export function assertDefined<T>(
  value: T | null | undefined,
  message: string,
): T {
  if (isDefined(value)) {
    return value;
  }

  throw new Error(message);
}

export function isDefined<T>(
  value: T | undefined | null,
): value is NonNullable<T> {
  return value !== undefined && value !== null;
}
