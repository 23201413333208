import { AvatarProps, ElementProps, Menu } from "@mantine/core";

import Avatar from "@/components/Avatar";
import useAccount from "@/hooks/useAccount";
import { useLogout } from "@/services/resources/auth";
import { Cog, LogOut } from "lucide-react";
import { Link } from "react-router-dom";
import { routes } from "@/routes";

type CurrentUserProps = Omit<AvatarProps, "src" | "alt"> &
  ElementProps<"div", keyof AvatarProps>;

export function CurrentUser(props: CurrentUserProps) {
  const { mutate: logout } = useLogout();
  const user = useAccount();

  const handleLogout = () => {
    logout();
  };

  return (
    <Menu>
      <Menu.Target>
        <Avatar
          {...props}
          style={{ cursor: "pointer", ...props.style }}
          user={user}
        />
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>Settings</Menu.Label>
        <Menu.Item
          component={Link}
          leftSection={<Cog size="1rem" />}
          to={routes.account.settings}
        >
          Account settings
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item leftSection={<LogOut size="1rem" />} onClick={handleLogout}>
          Logout
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
