import { ElementType } from "react";

import { routes } from "@/routes/paths";
import { TFunction } from "i18next";
import { Cog, CreditCard, MessageCircleQuestion, Utensils } from "lucide-react";

interface MenuItem {
  header: string;
  section: {
    name: string;
    href: string;
    icon: ElementType;
    dropdownItems?: {
      name: string;
      href: string;
      badge?: string;
    }[];
  }[];
}

export const getMenu = (t: TFunction<"translation", undefined>): MenuItem[] => [
  {
    header: t("dashboard"),
    section: [
      {
        name: t("restaurants"),
        href: routes.dashboard.root,
        icon: Utensils,
      },
    ],
  },
  {
    header: t("account"),
    section: [
      {
        name: t("billing"),
        href: routes.account.billing,
        icon: CreditCard,
      },
      {
        name: t("settings"),
        href: routes.account.settings,
        icon: Cog,
      },
      {
        name: t("support"),
        href: routes.account.support,
        icon: MessageCircleQuestion,
      },
    ],
  },
];
