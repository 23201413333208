import { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";

import LoadingScreen from "@/components/LoadingScreen";
import LogRocketAnalyics from "@/components/LogRocketAnalyics";
import useAuth from "@/hooks/useAuth";
import { routes } from "@/routes";

interface AuthGuardProps {
  children: ReactNode;
}

export function AuthGuard({ children }: AuthGuardProps) {
  const { pathname } = useLocation();
  const { isAuthenticated, isInitialized } = useAuth();

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    return <Navigate replace to={`${routes.auth.login}?r=${pathname}`} />;
  }

  return (
    <>
      {children}
      <LogRocketAnalyics />
    </>
  );
}
