import {
  Box,
  Button,
  Center,
  Flex,
  SimpleGrid,
  Text,
  Title,
} from "@mantine/core";
import { Outlet, useNavigate } from "react-router-dom";

import Logo from "@/components/Logo";
import { ArrowLeft, ArrowRight } from "lucide-react";
import { useTranslation } from "react-i18next";

export function AuthLayout() {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === "rtl";
  const navigate = useNavigate();

  return (
    <SimpleGrid cols={{ base: 1, lg: 2 }} mih="100vh" p="md">
      <Flex align="flex-start" direction="column">
        <Button
          c="inherit"
          leftSection={
            isRTL ? <ArrowRight size="1rem" /> : <ArrowLeft size="1rem" />
          }
          variant="subtle"
          onClick={() => navigate(-1)}
        >
          {t("go-back")}
        </Button>

        <Center flex={1} w="100%">
          <Box maw="25rem">
            <Logo
              c="var(--mantine-primary-color-filled)"
              display="block"
              mb="xl"
              size="3rem"
            />
            <Outlet />
          </Box>
        </Center>
      </Flex>

      <Center
        bg="var(--mantine-color-default-hover)"
        display={{ base: "none", lg: "flex" }}
        p="4rem"
        style={{ borderRadius: "var(--mantine-radius-md)" }}
        ta="center"
      >
        <Box maw="40rem">
          <Title order={2}>
            {t("transform-your-menu-into-a-digital-experience")}
          </Title>
          <Text c="dimmed" my="lg">
            {t("transform-your-menu-into-a-digital-experience-description")}
          </Text>
        </Box>
      </Center>
    </SimpleGrid>
  );
}
