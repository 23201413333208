import { MantineThemeComponents } from "@mantine/core";
import badge from "./badge";
import breadcrumbs from "./breadcrumbs";
import buttons from "./buttons";
import card from "./card";
import drawer from "./drawer";
import inputs from "./inputs";
import loadingOverlay from "./loading-overlay";
import menu from "./menu";
import notification from "./notification";
import ringProgress from "./ring-progress";
import table from "./table";
import tooltip from "./tooltip";

const components: MantineThemeComponents = {
  ...card,
  ...badge,
  ...breadcrumbs,
  ...buttons,
  ...drawer,
  ...inputs,
  ...loadingOverlay,
  ...menu,
  ...notification,
  ...ringProgress,
  ...table,
  ...tooltip,
};

export default components;
